<template>
  <b-card no-body class="mb-0">
    <b-card-body class="pb-1">
      <b-tabs
        vertical
        content-class="col-12 col-md-9 mt-1 mt-md-0"
        pills
        nav-wrapper-class="col-md-3 col-12"
        nav-class="nav-left"
      >
        <b-tab active>
          <template #title>
            <feather-icon icon="UserIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">Kişiler</span>
          </template>
          <account-setting-general />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="HomeIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">Adreslerim</span>
          </template>
          <account-setting-information />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="InfoIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">Şifre Değiştir</span>
          </template>
          <account-setting-password />
        </b-tab>
      </b-tabs>
    </b-card-body>
  </b-card>
</template>

<script>
import { BTabs, BTab, BCard, BCardText, BCardBody } from "bootstrap-vue";
import AccountSettingGeneral from "./AccountSettingGeneral.vue";
import AccountSettingPassword from "./AccountSettingPassword.vue";
import AccountSettingInformation from "./AccountSettingInformation.vue";
import AccountSettingSocial from "./AccountSettingSocial.vue";
import AccountSettingNotification from "./AccountSettingNotification.vue";

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    BCardText,
    BCardBody,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingInformation,
    AccountSettingSocial,
    AccountSettingNotification,
  },
  data() {
    return {
      options: {},
    };
  },
};
</script>
