<template>
  <b-overlay
    id="overlay-background"
    :show="show"
    variant="primary"
    opacity="0.85"
    blur="2"
    rounded="sm"
  >
    <b-card>
      <validation-observer ref="simpleRules">
        <b-form class="mt-2">
          <b-row>
            <b-col sm="4">
              <b-form-group label="Adı" label-for="account-username">
                <validation-provider
                  #default="{ errors }"
                  name="First Name"
                  rules="required"
                >
                  <b-form-input
                    id="input-live"
                    v-model="addDealerRequestModel.fullName"
                    placeholder="Adı"
                    :state="errors.length > 0 ? false : null"
                    name="username"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group label="Ünvanı" label-for="account-name">
                <validation-provider
                  #default="{ errors }"
                  name="First Name"
                  rules="required"
                >
                  <b-form-input
                    v-model="addDealerRequestModel.title"
                    name="name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Ünvanı"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group label="E-mail" label-for="account-e-mail">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    v-model="addDealerRequestModel.email"
                    name="email"
                    :state="errors.length > 0 ? false : null"
                    type="email"
                    placeholder="Email"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>

            <!--/ alert -->

            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                @click.prevent="savePerson"
              >
                Kaydet
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                type="reset"
                class="mt-2"
                @click.prevent="resetForm"
              >
                Temizle
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <br />
      <b-table
        :striped="true"
        :bordered="true"
        :hover="true"
        :small="true"
        ref="refUserListTable"
        class="position-relative"
        :items="persons"
        responsive
        :fields="tableColumns"
        primary-key="id"
        sort-by.sync="id"
        show-empty
        empty-text="Kayıt bulunamadı."
        head-variant="dark"
      >
        <template #cell(actions)="data">
          <div class="item-quantity d-flex justify-content-center">
            <b-button
              style="margin-top: 0x"
              type="button"
              variant="primary"
              @click="deletePerson(data)"
            >
              <feather-icon icon="TrashIcon" class="mr-50" />
              <span class="align-middle">Sil</span>
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BTable,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BOverlay,
  BImg,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import { required, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BImg,
    ToastificationContent,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BTable,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      profileFile: null,
      addDealerRequestModel: {},
      persons: [],
      tableColumns: [
        { key: "fullName", label: "Adı" },
        { key: "title", label: "Ünvanı" },
        { key: "email", label: "Email" },
        { key: "actions", label: "İşlem" },
      ],
      required,
      email,
    };
  },
  created() {
    this.init();
  },
  methods: {
    resetForm() {
      this.addDealerRequestModel = {};
    },
    deletePerson(data) {
      this.show = true;
      this.$http
        .get(`/User/DeleteDealerContact?id=${data.item.id}`)
        .then((response) => {
          this.init();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Silme başarılı",
              icon: "UserIcon",
              text: response.data.message,
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.show = false;

          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false,
          });
        });
    },
    savePerson() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.show = true;
          this.$http
            .post("User/AddDealerContact", this.addDealerRequestModel)
            .then((response) => {
              this.init();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Başarılı",
                  icon: "UserIcon",
                  text: response.data.message,
                  variant: "success",
                },
              });
              this.addDealerRequestModel={};
            })
            .catch((error) => {
              this.show = false;

              this.$swal({
                title: "Hata!",
                text: error.response.data.responseException.exceptionMessage,
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                confirmButtonText: "Kapat",
                buttonsStyling: false,
              });
            });
        } else {
          this.$swal({
            title: "Hata!",
            text: "Lütfen tüm alanları doldurduğunuzdan emin olunuz.",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false,
          });
        }
      });
    },
    init() {
      this.show = true;
      this.$http
        .get("/User/GetDealerContact")
        .then((response) => {
          this.persons = response.data.result;
          this.show = false;
        })
        .catch((error) => {
          this.show = false;

          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false,
          });
        });
    },
  },
};
</script>
