<template>
  <b-overlay
    id="overlay-background"
    :show="show"
    variant="primary"
    opacity="0.85"
    blur="2"
    rounded="sm"
  >
    <b-card>
      <b-form class="mt-2">
        <b-row>
          <b-col sm="4">
            <b-form-group label="Aktif Şifre" label-for="account-username">
              <b-form-input
                id="input-live"
                v-model="addDealerRequestModel.OldPassword"
                placeholder="Aktif Şifre"
                type="password"
                name="username"
              />
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group label="Yeni Şifre" label-for="account-name">
              <b-form-input
                id="input-live"
                v-model="addDealerRequestModel.NewPassword"
                placeholder="Aktif Şifre"
                type="password"
                name="username"
              />
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group label="Yeni Şifre" label-for="account-name">
              <b-form-input
                id="input-live"
                v-model="addDealerRequestModel.TryNewPassword"
                placeholder="Yeni Şifre"
                type="password"
                name="username"
              />
            </b-form-group>
          </b-col>
          <!--/ alert -->

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              @click="changePassword"
            >
              Kaydet
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetForm"
            >
              Temizle
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <br />
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BTable,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BOverlay,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required, email } from "@validations";
import useJwt from "@/auth/jwt/useJwt";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BButton,
    BForm,
    BImg,
    ToastificationContent,
    BFormFile,
    BFormGroup,
    BFormInput,
    BOverlay,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BTable,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      addDealerRequestModel: {},
      persons: [],
      required,
      email,
      show: false,
    };
  },
  created() {},
  methods: {
    resetForm() {
      this.addDealerRequestModel = {};
    },
    changePassword() {
      this.show = true;
      this.$http
        .post("Auth/ChangePassword", this.addDealerRequestModel)
        .then((response) => {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Başarılı",
              icon: "UserIcon",
              text: response.data.message,
              variant: "success",
            },
          });
          this.addDealerRequestModel = {};
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
          localStorage.removeItem("userData");
        })
        .catch((error) => {
          console.log(error);
          this.show = false;
          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false,
          });
        });
    },
  },
};
</script>
